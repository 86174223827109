import React, { useState, useEffect } from 'react';
import * as styles from './index.module.scss';

import { Link } from 'gatsby';

import RichText from '../../../../lib/contentful/rich-text';

import QMLogo from './../../../../images/footer/qm.png';

import MacLogo from './../../../../images/footer/mac.png';
import GALogo from './../../../../images/footer/ga.png';

import YesLogo from './../../../../images/footer/yes.svg';
import WestLogo from './../../../../images/footer/west-brom-logo.svg';
import StelradLogo from './../../../../images/footer/stelrad.png';
import IdealCommercialsLogo from './../../../../images/footer/ideal-commercial-boilers-logo.svg';
import DisabilityConfidentEmployer from './../../../../images/footer/disability-confident-employer.png';

import AndysManClubLogo from './../../../../images/footer/andysMC_logo_1.png';

const Wrapper = ({
  title,
  children
}) => (
  <div className={styles.section}>
    {title}
    {children}
  </div>
)

export const FooterTop = ({ data }) => {

  const {
    sectionTitle,
    footerLinks,
  } = data;

  return (
    <Wrapper title={RichText(sectionTitle)}>
      <ul className={styles.menu}>
        {footerLinks.map((link, index) => 
          <li key={`footer-link-${index}`} className={styles.item}>
            <a href={link.url} alt={link.menuItemName}>{link.menuItemText}</a>
          </li>
        )}
      </ul>
    </Wrapper>
  )
}

export const FooterAwards = () => {

    const [currentYear, setCurrentYear] = useState(null);
    
    useEffect(() => {
      setCurrentYear(new Date().getFullYear());
    }, []);

  	return (
		<Wrapper title={<h3>Our awards<strong>.</strong></h3>}>
			<ul className={styles.awards}>
				<li><img className={styles.awardsLogo} src={QMLogo} alt="Quiet Mark award" /></li>
			</ul>
		</Wrapper>
  	);
};

export const FooterPartnerships = ({ minimal = false }) => (
  <Wrapper title={!minimal ? <h3>Partnerships<strong>.</strong></h3> : null}>
    {minimal
      ? (
        <>
          <ul className={`${styles.awards} ${styles.minimalAwards}`}>
            <li><img className={styles.awardsFullLogo} src={MacLogo} alt="Official Partner of Macmillan cancer support" /></li>
            <li><img className={styles.awardsFullLogo} src={GALogo} alt="Group Ataltic group" /></li>
            <li><img className={`${styles.awardsFullLogo} ${styles.small}`} src={StelradLogo} alt="Stelrad" /></li>
            <li><img className={styles.awardsFullLogo} src={WestLogo} alt="West Bromwich Albion" /></li>
            <li><img className={styles.awardsFullLogo} src={YesLogo} alt="Oh Yes" /></li>
            <li><img className={styles.awardsFullLogo} src={AndysManClubLogo} alt="Andy's Man Club" /></li>
            <li><img className={styles.awardsFullLogo} src={DisabilityConfidentEmployer} alt="Disability Confident Employer" /></li>
          </ul>
        </>
      ) : (
        <>
          <ul className={styles.awards}>
            <li>
              <Link to="/blog/ideal-heating-partners-with-macmillan-cancer-support">
                <img className={styles.awardsFullLogo} src={MacLogo} alt="Official Partner of Macmillan cancer support" />
              </Link>
            </li>
            <li>
              <Link to="/our-brands">
                <img className={styles.awardsFullLogo} src={GALogo} alt="Groupe Atlantic logo" />
              </Link>
            </li>
            <li>
              <a href="https://idealcommercialboilers.com/" target="_blank" rel="noopener noreferrer">
                <img className={`${styles.awardsFullLogo} ${styles.awardsLogoSVG}`} src={IdealCommercialsLogo} alt="Ideal Commercial Boilers logo" />
              </a>
            </li>
          </ul>
          <ul className={`${styles.awards} ${styles.spaceBetween}`}>
            <li><img className={`${styles.awardsFullLogo} ${styles.small}`} src={StelradLogo} alt="Stelrad" /></li>
            <li>
              <Link to="/blog/weve-extended-our-partnership-with-west-bromwich-albion-for-a-record-ninth-season">
                <img className={styles.awardsFullLogo} src={WestLogo} alt="West Bromwich Albion" />
              </Link>
            </li>
            <li>
              <Link to="/blog/we-said-oh-yes-to-net-zero-as-we-shared-our-low-carbon-journey">
                <img className={styles.awardsFullLogo} src={YesLogo} alt="Oh Yes" />
              </Link>
            </li>
            <li>
              <Link to="/andys-man-club">
                <img className={styles.awardsFullLogo} src={AndysManClubLogo} alt="Andy's Man Club" />
              </Link>
            </li>
            <li>
              <Link to="/disability-confident-employer">
                <img className={styles.awardsFullLogo} src={DisabilityConfidentEmployer} alt="Disability Confident Employer" />
              </Link>
            </li>
          </ul>
        </>
      )
    }
  </Wrapper>

)